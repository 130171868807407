<template>
  <el-dialog
    append-to-body
    :before-close="handleClose"
    :title="title"
    :visible.sync="dialogVisible"
    width="40%"
  >
    <Form :column="column" :label-width="100" :query="query" />
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button :loading="loading" type="primary" @click="onSubmit">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { roleSave } from '@/api/user'
  import Form from '@/components/Form'
  export default {
    components: {
      Form,
    },
    data() {
      return {
        title: null,
        dialogVisible: false,
        loading: false,
        query: {
          name: null,
          code: null,
        },
      }
    },
    computed: {
      column() {
        return [
          {
            label: '角色名称',
            prop: 'name',
          },
          {
            label: '编码',
            prop: 'code',
            tips: '支持英文字母和数字，唯一不重复即可',
          },
        ]
      },
    },
    methods: {
      handleOpen(title) {
        this.title = title
        this.dialogVisible = true
      },
      handleClose(done) {
        done()
        this.cancel()
      },
      // 提交
      async onSubmit() {
        if (this.loading) return
        this.loading = true
        const params = this.query
        const res = await roleSave(params)
        this.loading = false
        if (res.success) {
          this.cancel()
          this.$emit('getTableData')
          this.$message.success('保存成功')
        }
      },
      // 取消
      cancel() {
        this.dialogVisible = false
        setTimeout(() => {
          for (let key in this.query) {
            this.query[key] = null
          }
        }, 500)
      },
    },
  }
</script>

<style lang="scss" scoped></style>
