var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "permission-config-container" },
    [
      _c("div", { staticClass: "permission-config-tips" }, [
        _vm._v(" 对客户端管理员进行不同的管理权限分配 "),
      ]),
      _c("TablePaging", {
        attrs: {
          "button-list": _vm.buttonList,
          "column-list": _vm.columnList,
          "is-pagination": false,
          loading: _vm.loading,
          "page-info": _vm.pageInfo,
          "table-data": _vm.list,
        },
        on: {
          handleChangeMenu: _vm.handleChangeMenu,
          handleCreate: _vm.handleCreate,
          handleCurrentChange: _vm.handleCurrentChange,
          handleRefresh: _vm.handleRefresh,
          handleRemove: _vm.handleRemove,
        },
      }),
      _c("AddDialog", {
        ref: "addDialog",
        on: { getTableData: _vm.getTableData },
      }),
      _c("MenuDialog", {
        ref: "menuDialog",
        on: { getTableData: _vm.getTableData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }