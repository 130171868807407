<template>
  <div class="permission-config-container">
    <div class="permission-config-tips">
      对客户端管理员进行不同的管理权限分配
    </div>
    <!-- 表格 -->
    <TablePaging
      :button-list="buttonList"
      :column-list="columnList"
      :is-pagination="false"
      :loading="loading"
      :page-info="pageInfo"
      :table-data="list"
      @handleChangeMenu="handleChangeMenu"
      @handleCreate="handleCreate"
      @handleCurrentChange="handleCurrentChange"
      @handleRefresh="handleRefresh"
      @handleRemove="handleRemove"
    />
    <AddDialog ref="addDialog" @getTableData="getTableData" />
    <MenuDialog ref="menuDialog" @getTableData="getTableData" />
  </div>
</template>

<script>
  import { getRolelist, refreshRole, roleDel } from '@/api/user'
  import TablePaging from '@/components/Table'
  import tableMixins from '@/mixins/tableCommon.vue'
  import AddDialog from './components/addDialog'
  import MenuDialog from './components/menuDialog'
  export default {
    components: {
      TablePaging,
      AddDialog,
      MenuDialog,
    },
    mixins: [tableMixins],
    data() {
      return {
        buttonList: Object.freeze([
          {
            label: '添加权限',
            type: 'primary',
            icon: 'el-icon-plus',
            eventName: 'handleCreate',
          },
          {
            label: '刷新缓存',
            type: 'primary',
            icon: 'el-icon-refresh',
            eventName: 'handleRefresh',
          },
        ]),
      }
    },
    computed: {
      columnList() {
        return [
          { label: '用户名', prop: 'name' },
          { label: '编码', prop: 'code' },
          {
            label: '操作',
            isButton: true,
            btnList: [
              {
                label: '拥有所有权限',
                type: 'text',
                showName: 'code',
                showStatusList: ['normal_manager'],
              },
              {
                label: '菜单',
                type: 'text',
                eventName: 'handleChangeMenu',
                showName: 'code',
                showStatusList: this.showStatus(),
              },
              {
                label: '删除',
                type: 'text',
                eventName: 'handleRemove',
                showName: 'code',
                showStatusList: this.showStatus(),
              },
            ],
          },
        ]
      },
    },
    mounted() {
      this.getTableData().then(() => {
        this.showStatus()
      })
    },
    methods: {
      async getTableData() {
        this.loading = true
        const params = {
          page: this.pageInfo.page,
          step: this.pageInfo.step,
        }
        const res = await getRolelist(params)
        this.loading = false
        this.list = res.list || []
      },
      showStatus() {
        let list = []
        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i].code != 'normal_manager') {
            list.push(this.list[i].code)
          }
        }
        return list
      },
      // 菜单
      handleChangeMenu(val) {
        this.$refs.menuDialog.handleOpen(val)
      },
      // 删除
      handleRemove(val, index, buttonName) {
        const params = { id: val.id }
        this.$confirm(`此操作将${buttonName}当前数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            const res = await roleDel(params)
            this.$message({
              type: res.success ? 'success' : 'error',
              message: res.success ? `${buttonName}成功` : `${buttonName}失败`,
            })
            if (res.success) this.getTableData()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消',
            })
          })
      },
      // 刷新缓存
      async handleRefresh() {
        const res = await refreshRole()
        if (res.success) {
          this.$message.success('刷新成功')
          this.getTableData()
        }
      },
      // 添加权限
      handleCreate() {
        this.$refs.addDialog.handleOpen('添加权限')
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.permission-config';
  #{$base}-container {
    #{$base}-title {
      margin: 15px 0;
      font-size: 16px;
      font-weight: bold;
    }
    #{$base}-info {
      font-size: 13px;
      color: $base-color-text-secondary;
      margin-bottom: 20px;
      span {
        color: $base-color-red;
      }
    }
    #{$base}-tips {
      margin-bottom: 20px;
      font-size: 13px;
      color: $base-color-red;
    }
  }
</style>
