<template>
  <el-dialog
    append-to-body
    :before-close="handleClose"
    :title="title"
    :visible.sync="dialogVisible"
    width="80%"
  >
    <!-- 表格 -->
    <TablePaging
      :column-list="columnList"
      :is-pagination="false"
      :loading="loading"
      :max-height="maxHeight"
      :page-info="pageInfo"
      :table-data="menuList"
    >
      <!-- 菜单 -->
      <template #menu="{ row }">
        <el-checkbox v-model="row.checked">{{ row.name }}</el-checkbox>
      </template>
      <!-- 功能区 -->
      <template #func="{ row }">
        <el-checkbox-group
          v-if="row.actions && row.actions.length"
          v-model="row.checkList"
        >
          {{ row.actions }}
          <el-checkbox
            v-for="(item, index) in row.actions"
            :key="index"
            :label="item.code"
          >
            {{ item.name }}
          </el-checkbox>
        </el-checkbox-group>
      </template>
    </TablePaging>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button :loading="loading" type="primary" @click="onSubmit">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { menuRoleSave, getMenuRolelist } from '@/api/user'
  import TablePaging from '@/components/Table'
  import tableMixins from '@/mixins/tableCommon.vue'
  export default {
    components: {
      TablePaging,
    },
    mixins: [tableMixins],
    data() {
      return {
        title: null,
        dialogVisible: false,
        loading: false,
        menuList: [],
        id: null,
        // 表格最大高度
        maxHeight: document.body.clientHeight - 380,
        columnList: Object.freeze([
          { label: '菜单', slotName: 'menu', align: 'left' },
          { label: '功能区', slotName: 'func', align: 'left' },
        ]),
      }
    },
    methods: {
      handleOpen(row) {
        this.title = row.name
        this.dialogVisible = true
        this.id = row.id
        this.getMenuRolelist(row)
      },
      async getMenuRolelist(row) {
        const params = {
          role_id: row.id,
        }
        this.loading = true
        const res = await getMenuRolelist(params)
        this.loading = false
        if (res.success && res.list.length) {
          let menuList = []
          for (let i = 0; i < res.list.length; i++) {
            menuList.push(res.list[i])
            if (res.list[i].childs && res.list[i].childs.length) {
              for (let el of res.list[i].childs) {
                el.name = '-- ' + el.name
              }
              menuList.push(...res.list[i].childs)
            }
          }
          for (let item of menuList) {
            if (item.actions && item.actions.length) {
              item.checkList = []
              for (let el of item.actions) {
                if (el.checked) {
                  item.checkList.push(el.code)
                }
              }
            }
          }
          this.menuList = menuList
        }
      },
      handleClose(done) {
        done()
        this.cancel()
      },
      // 提交
      async onSubmit() {
        if (this.loading) return
        this.loading = true
        let menurole = []
        for (let item of this.menuList) {
          if (item.checked && item.checkList) {
            let obj = {
              menu_id: item.id,
              actions_code: item.checkList.join(','),
            }
            menurole.push(obj)
          }
        }
        const params = {
          role_id: this.id,
          menurole: JSON.stringify(menurole),
        }
        const res = await menuRoleSave(params)
        this.loading = false
        if (res.success) {
          this.cancel()
          this.$emit('getTableData')
          this.$message.success('保存成功')
        }
      },
      // 取消
      cancel() {
        this.dialogVisible = false
        setTimeout(() => {
          this.menuList = []
        }, 500)
      },
    },
  }
</script>

<style lang="scss" scoped></style>
