var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        "before-close": _vm.handleClose,
        title: _vm.title,
        visible: _vm.dialogVisible,
        width: "80%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("TablePaging", {
        attrs: {
          "column-list": _vm.columnList,
          "is-pagination": false,
          loading: _vm.loading,
          "max-height": _vm.maxHeight,
          "page-info": _vm.pageInfo,
          "table-data": _vm.menuList,
        },
        scopedSlots: _vm._u([
          {
            key: "menu",
            fn: function (ref) {
              var row = ref.row
              return [
                _c(
                  "el-checkbox",
                  {
                    model: {
                      value: row.checked,
                      callback: function ($$v) {
                        _vm.$set(row, "checked", $$v)
                      },
                      expression: "row.checked",
                    },
                  },
                  [_vm._v(_vm._s(row.name))]
                ),
              ]
            },
          },
          {
            key: "func",
            fn: function (ref) {
              var row = ref.row
              return [
                row.actions && row.actions.length
                  ? _c(
                      "el-checkbox-group",
                      {
                        model: {
                          value: row.checkList,
                          callback: function ($$v) {
                            _vm.$set(row, "checkList", $$v)
                          },
                          expression: "row.checkList",
                        },
                      },
                      [
                        _vm._v(" " + _vm._s(row.actions) + " "),
                        _vm._l(row.actions, function (item, index) {
                          return _c(
                            "el-checkbox",
                            { key: index, attrs: { label: item.code } },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, type: "primary" },
              on: { click: _vm.onSubmit },
            },
            [_vm._v(" 确 定 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }